<script>
import {getBmsDriverAccountDetailApi, getWalletDataApi} from "@/www/urls/wallet";
import moment from "moment";

export default {
    name: "DriverBalanceDetail",
    data() {
        return {
            loading: false,
            record: {},
            list: [],
            total: 0,
            form: {
                pageIndex: 1,
                pageSize: 10,
                month: undefined,
                type: -1
            },
            result: {},
            options: {},
            status: [
                {
                    value: 0,
                    label: '待结算'
                },
                {
                    value: 1,
                    label: '已入账'
                },
                {
                    value: 2,
                    label: '提现中'
                },
                {
                    value: 3,
                    label: '已提现'
                },
                {
                    value: 4,
                    label: '提现失败'
                }
            ]
        }
    },
    mounted() {
        this.record = JSON.parse(this.$route.query.record)
        this.getList();
        this.getOptions();
    },
    computed: {
        income() {
            return this.result.payDriverAccountDetailDtos.find(({type}) => type === 1) || {}
        },
        withdrawal() {
            return this.result.payDriverAccountDetailDtos.find(({type}) => type === 0) || {}
        }
    },
    methods: {
        async getList() {
            try {
                this.loading = true
                const ret = await getBmsDriverAccountDetailApi({
                    accountId: this.record.driverAccountId,
                    ...this.form,
                    type: this.form.type === -1 ? undefined : this.form.type,
                    startCreateTime: this.form.month ? moment(this.form.month).startOf('M').valueOf() : undefined,
                    endCreateTime: this.form.month ? moment(this.form.month).endOf('M').valueOf() : undefined,
                    month: undefined
                })
                if (ret.success && ret.data) {
                    this.list = ret.data.payDriverAccountDetailDtoPageList.data;
                    this.total = ret.data.payDriverAccountDetailDtoPageList.total
                    this.result = ret.data
                }
            } finally {
                this.loading = false
            }
        },
        async getOptions() {
            try {
                this.options = await getWalletDataApi()
            } finally {
                console.log(3323223, this.options);
            }
        },
        onRefresh() {
            this.form.pageIndex = 1
            this.getList()
        }
    }
}
</script>

<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer driver-balance-detail">
            <div class="eTrip-section-cont">
                <el-descriptions :column="6" border class="section">
                    <el-descriptions-item label="司机姓名" :span="3">{{ record.driverName }}</el-descriptions-item>
                    <el-descriptions-item label="服务城市" :span="3">{{ record.cityName }}</el-descriptions-item>
                    <el-descriptions-item label="司机手机" :span="3">{{ record.driverMobile }}</el-descriptions-item>
                    <el-descriptions-item label="所属运营商" :span="3">{{ record.operatorName }}</el-descriptions-item>
                    <el-descriptions-item label="绑定提现账户" :span="3">{{ record.realName }}{{ record.aliPayAccount }}
                    </el-descriptions-item>
                    <el-descriptions-item label="所属供应商" :span="3">{{ record.supplierName }}</el-descriptions-item>
                    <!--                <el-descriptions-item label="车租代缴" :span="3">已开启，每月15号自动代缴6000.00元</el-descriptions-item>-->
                    <!--                <el-descriptions-item label="收款公司" :span="3">某某某租赁车有限公司（账号：GYSZH@163.com-->
                    <!--                </el-descriptions-item>-->
                    <el-descriptions-item label="当前余额" :span="2">
                        {{ record.totalPrice }}
                    </el-descriptions-item>
                    <el-descriptions-item label="可提现余额" :span="2">
                        {{
                            record.accountBalance
                        }}
                    </el-descriptions-item>
                    <el-descriptions-item label="冻结余额" :span="2">{{ record.freezePrice }}</el-descriptions-item>
                </el-descriptions>
                <div class="section mt10" v-loading="loading">
                    <ul class="search-hd ">
                        <li>
                            <el-date-picker type="month" v-model="form.month" placeholder="请选择日期" clearable
                                            @change="onRefresh"></el-date-picker>
                        </li>
                        <li class="ml20">
                            <ApiSelect style="width: 120px" :options="options.billingTypes" v-model="form.type"
                                       placeholder="请选择类型"
                                       clearable @change="onRefresh">
                                <el-option :value="-1" label="全部">全部</el-option>
                            </ApiSelect>
                        </li>
                        <li class="ml20">
                            <el-descriptions :colon="false" :column="4">
                                <el-descriptions-item label="车费">+¥{{ income.totalAccountPrice  | currency }}
                                </el-descriptions-item>
                                <el-descriptions-item label="提现">-¥{{ withdrawal.totalAccountPrice | currency }}
                                </el-descriptions-item>
                                <!--                            <el-descriptions-item label="车租代缴">￥- 6000.00</el-descriptions-item>-->
                                <!--                            <el-descriptions-item label="系统调账">￥-20.00</el-descriptions-item>-->
                            </el-descriptions>
                        </li>
                    </ul>
                    <el-table border :data="list">
                        <el-table-column label="日期时间" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.createTime | dateCus }}
                            </template>
                        </el-table-column>
                        <el-table-column label="类型" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.type | formatDesc(options.billingTypes) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="金额" prop="accountPrice" align="center"></el-table-column>
                        <el-table-column label="状态" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.status | formatDesc(status)}}
                            </template>
                        </el-table-column>
                        <el-table-column label="到账时间" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.updateTime | dateCus }}
                            </template>
                        </el-table-column>
                        <el-table-column label="订单号/流水号" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.aliOrderNo || scope.row.orderNo }}
                            </template>
                        </el-table-column>
                        <el-table-column label="收款单位和帐号" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.realName || '' }}{{scope.row.aliPayAccount||''}}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px" v-if="list.length>0">
            <div class="eTrip-footer-cont">
                <el-pagination
                    @current-change="getList"
                    :current-page.sync="form.pageIndex"
                    :page-size="form.pageSize"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </el-footer>
    </div>
</template>

<style scoped lang="scss">
.driver-balance-detail {
    .search-hd {
        padding: 10px 0;
        display: flex;
        align-items: center;

        &::v-deep {
            .el-descriptions-item__cell {
                padding-bottom: 0;
            }
        }
    }

    .section {
        background-color: #fff;
        padding: 10px 20px;
    }

}

.eTrip-footer-cont {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 0;
}
</style>
